.layoutWithoutSidebar {
  display: flex;
  height: 100vh;
  background-color: var(--main01);
}

.main-container {
  width: calc(100% - 270px);
}

@media (max-width: 1375px) {
  .sidebar-container.open {
    position: fixed;
    z-index: 99999;
  }
}
