label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
  color: var(--main800);
}

label span:hover {
  background-color: var(--main01);
}

label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: var(--mainNavHover);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
}

form :is(input, select, textarea) ~ span.inputError {
  display: none;
}

form
  :is(
    input[focus="true"]:invalid,
    select[focus="true"]:invalid,
    textarea[focus="true"]:invalid
  )
  ~ span.inputError {
  display: block;
  color: red;
  font-size: 13px;
}

form
  :is(
    input[focus="true"]:invalid,
    select[focus="true"]:invalid,
    textarea[focus="true"]:invalid
  ) {
  border-color: red;
}

.list-group.list-group-flush .btn:active {
  border: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: all 0.4s;
  border: 1px solid;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--main800);
  transition: all 0.4s;
  border: 1px solid;
}

input:checked + .slider {
  background-color: var(--main800);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--main01);
}

input:checked + .slider:before {
  transform: translateX(26px);
  border: 0;
  background: white;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.accordionBody {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: all 400ms;
  border-bottom: 0px solid var(--main800);
  border-top: 0px solid var(--main800);
}

.accordionBody > div {
  min-height: 0;
}

.accordionContainer.open .accordionBody {
  grid-template-rows: 1fr;
}

.accordionContainer svg {
  transition: all 200ms;
  transform: rotate(0deg);
  transform-origin: center;
  margin-right: 5px;
  font-size: 0.8rem;
}

.accordionContainer.open svg.chevron {
  transform: rotate(90deg);
}

.accordion > div.accordionContainer:not(:nth-of-type(1)) {
  margin-top: 10px;
}

.accordion > i {
  text-align: end;
  font-size: 12px;
  margin-top: 10px;
}

div.btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

div.btn:hover,
div.btn:active {
  opacity: 0.8;
  border: 0;
}
