.imageWrapper,
.upload-icon {
  border: 1px solid #dee2e6;
  padding: 7px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-icon {
  padding: 15px 0;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.upload-icon:hover {
  background-color: var(--main01);
  cursor: pointer;
}

.upload-icon p {
  margin-bottom: 0;
}

.imageWrapper img {
  object-fit: cover;
  width: 100px;
  height: 45px;
}
