.header-container {
  height: 68px;
  background-color: var(--mainNavHover);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
}

.header-container > :first-child {
  cursor: pointer;
}

.header-container > :first-child:hover {
  opacity: 0.7;
}

.header-container :last-child {
  gap: 10px;
}

.header-container .avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.header-container svg,
.dropdown-img {
  cursor: pointer;
}

.dropdown-items {
  position: absolute;
  top: 53px;
  z-index: 999;
  text-align: start;
  background-color: white;
  padding: 10px 5px;
  width: 115px;
  border: 1px solid lightgray;
  border-radius: 0.375rem;
  color: var(--bs-secondary-color) !important;
  right: 60px;
  margin: 0;
}

.dropdown-items li {
  padding: 2px 5px 2px 7px;
  cursor: pointer;
}

.dropdown-items li:hover {
  background-color: var(--main008);
}

.logoutBtn {
  color: gray;
  cursor: pointer;
}

.logoutBtn:hover {
  opacity: 0.8;
}
