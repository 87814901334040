.delete-confirmation-modal,
.session-expired-modal,
.edit-profile-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.delete-confirmation-modal.show,
.session-expired-modal.show,
.edit-profile-modal.show {
  display: flex;
  opacity: 1;
}

.modal-content {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  max-width: 335px !important;
  min-width: 300px !important;
  text-align: center;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  margin: 0 10px;
}

.edit-profile-modal .modal-content {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  max-width: 525px !important;
  min-width: 300px !important;
  text-align: center;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  margin: 0 10px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.delete-icon {
  padding: 20px;
  /* background-color: var(--main800); */
  width: fit-content;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.delete-confirmation-modal.show .modal-content,
.session-expired-modal.show .modal-content,
.edit-profile-modal.show .modal-content {
  opacity: 1;
  transform: translateY(0);
}

.user-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.change-password-link {
  display: block;
  cursor: pointer;
  font-size: 13px;
  color: var(--bs-blue);
  text-decoration: underline;
}
