.sidebar-container {
  background-color: var(--main800);
  position: sticky;
  height: 100vh;
  top: 0;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.sidebar-container.open {
  opacity: 1;
  transform: translateX(0);
  width: 270px;
}

.sidebar-container.close {
  display: none;
}

.menu {
  padding: 10px 0;
  border-top: 1px solid var(--main800);
  color: var(--main01);
}

.menu > span {
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--grayish);
  font-weight: 600;
}

.sidebar-item {
  color: var(--grayish);
  text-decoration: none;
}

.sidebar-item.active {
  color: var(--mainNavHover);
}

.sidebar-item.active > div {
  background-color: #f5f7f8;
  color: var(--main800);
}

.sidebar-item:hover {
  color: var(--mainNavHover);
  cursor: pointer;
}

.sidebar-container::-webkit-scrollbar {
  width: 10px;
}

.sidebar-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebarDropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease, opacity 0.2s ease;
  opacity: 0;
}

.sidebarDropdown.expanded {
  max-height: 500px;
  opacity: 1;
  animation: dropdownAnimation 0.2s ease;
}

.sidebarDropdown.closed {
  max-height: 0;
  opacity: 0;
  animation: dropdownAnimationReverse 0.2s ease;
}

@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdownAnimationReverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10%);
  }
}
