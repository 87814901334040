div.table-container {
  overflow: auto;
}

table {
  /* min-width: 630px; */
  min-width: 990px;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
table th {
  white-space: nowrap;
}

table td,
table th {
  max-width: 290px;
  vertical-align: middle;
  padding: 12px 15px;
}

table td:last-child {
  width: 235px;
}

table img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  border: 1px solid lightgray;
}

td:has(img) {
  text-align: center;
}

table thead tr {
  background-color: var(--main05);
  color: #ffffff;
  text-align: left;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* @media (max-width: 1010px) {
  td {
    max-width: 100% !important;
    display: grid;
    grid-template-columns: 15ch 1fr;
    padding: 0.5rem 1rem;
    text-align: start !important;
    border: 0;
    grid-column: 1;
  }

  td:not(:has(> div > button))::before {
    content: attr(data-cell) ": ";
    text-transform: capitalize;
    font-weight: 600;
    white-space: nowrap;
    grid-column: 1;
    overflow-wrap: break-word; 
  }

  td:first-child {
    padding-top: 1rem !important;
  }

  td:last-child {
    padding-bottom: 2rem !important;
    display: flex;
    justify-content: center;
  }

  th {
    display: none;
  }

  .table > :not(caption) > * > * {
    border-bottom-width: 0 !important;
  }
  table td:last-child {
    width: 100%;
    justify-content: space-evenly !important;
  }
  table td:last-child > div {
    width: 100%;
  }
  td span,
  td img {
    text-align: center;
  }
} */
