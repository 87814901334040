* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-content {
  padding: 1rem 3rem;
  background-color: var(--main008);
  min-height: 100vh;
}

.btn {
  width: fit-content;
}

table th {
  white-space: nowrap;
}

table td,
table th {
  max-width: 290px;
  vertical-align: middle;
}

.badge-success-container {
  background-color: rgba(44, 181, 126, 0.18);
  color: #2cb57e;
  border-radius: 10px;
  padding: 2px 6px;
}

.badge-danger-container {
  background-color: rgba(245, 110, 80, 0.18);
  color: #f56e50;
  border-radius: 10px;
  padding: 2px 6px;
}

.login-btn {
  background-color: var(--main800) !important;
  color: var(--mainNavHover) !important;
  width: 100%;
  border-radius: 30px !important;
  height: 45px;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.forgotpassword-link {
  margin: 1rem 0;
}

.forgotpassword-link a {
  color: var(--main800);
  font-size: 14px;
  text-decoration: none;
}

.forgotpassword-link:hover {
  opacity: 0.8;
}

.error-message {
  font-size: 14px;
  color: red;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 300px;
  overflow: auto;
}

.ql-editor::-webkit-scrollbar {
  width: 10px;
}

.ql-editor::-webkit-scrollbar-track {
  background: transparent;
}

.ql-editor::-webkit-scrollbar-thumb {
  background: var(--main01);
  border-radius: 2px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background: var(--main05);
}

table td:last-child {
  width: 235px;
}

.remove-image-icon:hover,
.edit-icon:hover,
.delete--icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

button.mainBtn {
  background-color: var(--main800) !important;
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  color: white;
  min-width: 105px;
  max-width: fit-content;
  height: fit-content;
}

.mainBtn:hover {
  background-color: var(--main800);
  color: white !important;
  opacity: 0.8;
}

button.mainBtn:active {
  background-color: var(--main800) !important;
  color: white;
}

.btn.mainBtn:disabled {
  opacity: 0.85;
}

span.counter-value {
  color: var(--main800);
}

.forgetPasswordForm > div > p {
  margin-top: 25px;
  margin-bottom: 5px;
  background-color: var(--main01);
  padding: 5px 10px;
  border-radius: 5px;
}

.errorPage {
  text-align: center;
}

.errorPage h1 {
  font-size: 3rem;
  color: var(--main800);
  margin: 0;
  font-weight: 600;
}

.errorPage h5 {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  color: var(--main800);
  margin-bottom: 22px;
}

.errorPage p {
  font-size: 0.9rem;
  color: gray;
}

.errorPage button {
  background-color: var(--main800);
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  min-width: 140px;
  margin: 10px 0;
}

.errorPage button:hover {
  background-color: var(--main800);
  color: white;
  opacity: 0.8;
}

.profile-photo {
  width: 50px;
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 50%;
}

label input[type="checkbox"] {
  position: initial;
}

input[type="checkbox"],
input[type="radio"] {
  width: 17px;
  height: 17px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--main05);
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  aspect-ratio: 1;
  transition: all 0.3s ease-in;
  padding: 0 !important;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: var(--main05);
  border: 1px transparent;
  box-shadow: 0 2px 4px 0 var(--main01);
  background-image: url(./Assets/Images/checkmark.png);
  background-size: 60%;
}

.manageSettingsForm > div:not(:last-child) {
  border: 1px solid var(--main01);
  padding: 10px;
  border-top: 0;
}

.manageSettingsForm > div:first-child {
  border-top: 1px solid var(--main01);
}

div.newsStatus {
  text-align: end;
  font-size: 12px;
}

div.newsStatus > span {
  background-color: lightgray;
  padding: 4px 8px;
  border-radius: 7px;
  border: 1px solid #cecdcd;
}
